/* // Primary font */
$primary-font: Roboto, sans-serif;
$primary-font-bold: "Roboto Bold", sans-serif;
$primary-font-italic: "Roboto Italic", sans-serif;

/* // Secondary font */
$secondary-font-heavy: "CaeciliaLTStd-Heavy", serif;
$secondary-font-bold: "CaeciliaLTStd-Bold", serif;

$primary-color: #007e7a;
$primary2-color: #55b89a;
$primary-color-shadow: #007e7a33;

$core-green: #007e7a;
$core-yellow: #e9ab13;

$light-green1-opacity20: rgba(0, 165, 153, 0.2);
$dark-green: #004C47;

$dark-green1: #004a44;

$light-yellow1: #ffc943;
$light-yellow2: #7d6924;
$light-yellow3: #FFFBED;
$light-yellow4: #E5A709;
$light-yellow5: #755501;
$light-yellow6: #FFFBE0;
$light-yellow7: #fcf3d2;
$deadline-yellow: #ddac00;

$light-green1: #00a599;
$light-green2: #55b89a;
$light-green3: #129b62;
$light-green4: #cfecd5;
$light-green5: #CEF2F0;
$light-green6: #ccedeb;

$red: #dc2f37;
$primary-red: #d11212;
$light-red1: #ea575e;
$light-red2: #f6d0d0;
$light-red3: #FFE3E3;
$light-red4: #830808;
$light-red5: #d1121233;

$dark-grey1: #252525;
$dark-grey2: #2e2e2e;
$dark-grey3: #555555;
$dark-grey4: #969696;
$drak-grey5: #00000073;
$drak-grey6: #4d4d4d;

$light-grey1: #bcbec0;
$light-grey2: #d9d9d9;
$light-grey3: #a9a9a9;
$light-grey4: #eeeeee;
$acqua-grey: #e6e7e8;
$light-grey5: #fcfcfc;
$light-grey6: #f3f3f3;
$light-grey7: #f7f7f7;
$light-grey8: #f0f0f0;
$light-grey9: #F9F0FF;
$light-grey10: #ffffff00;
$light-grey11: #eee;
$light-grey12: #e5e7eb;
$light-grey13: #d1d5db;
$light-grey14: #b8b8b8;
$light-grey15: #F5F5F5;
$light-grey16: #f4f4f4;
$light-grey17: #0000000f;
$light-grey18: #2e2e2e7a;




$purple-dark1: #BA01FF;
$purple-dark2: #dd68ff4d;
$purple-dark3: #ba01ff66;

$purple-light1: #ECD0FE;

$light-grey-04:#f6f6f6;

$sidebar-gray: #f9f9f9;
$white: #ffffff;

$light-blue: #3f97c7;
$light-blue2: #61b3e0;
$light-blue3: #EAF7FF;

$dark-blue: #134C6C;
$dark-blue1: #2078A9;

$btn-disabled-bg: $light-grey4;
$btn-disabled-color: #bbbbbb;
$link-color1: #252525;
$link-section: #8b8888;


$dark-violet-01:#730394;
$light-violet: #F8E6FF;

$grey-hover:#f3f4f6;
